import styled from 'styled-components';

const textColor = (props) => props?.theme?.BlogText?.color ?? 'red';
const font = (props) => props?.theme?.BlogText?.fontFamily ?? 'inherit';
const fontSize = (props) => props?.theme?.BlogText?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.BlogText?.fontWeight ?? 'inherit';

const BlogTextStyles = styled.span`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    white-space: pre-wrap;
`;

export default BlogTextStyles;
