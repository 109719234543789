import BlogTitleStyles from './BlogTitleStyles';
import React from 'react';
import { Typography } from '@material-ui/core';

const BlogTitle = ({ children, ...props }) => {
    return (
        <Typography {...props} variant="h5" component="h1">
            <BlogTitleStyles>{children}</BlogTitleStyles>
        </Typography>
    );
};

export default BlogTitle;
