import CardImgStyles from './CardImgStyles';
import React from 'react';

import useResponsiveSizing from '../../../hooks/useResponsiveSizing';

const CardImg = ({ variant = 'smallCard', alt, fluid, title }) => {
    let imgHeights = [];
    if (variant === 'listCard') {
        imgHeights = [
            // ['60vh', null],
            ['385px', '385px'],
            ['100%', '385px'],
            ['220px', '220px'],
            ['220px', '220px'],
            ['220px', '220px'],
        ];
    } else {
        imgHeights = [
            ['385px', '385px'],
            ['100%', '385px'],
            ['28vw', null],
            ['370px', null],
            ['370px', null],
        ];
    }

    return <CardImgStyles imgHeight={useResponsiveSizing(imgHeights)} alt={alt} fluid={fluid} title={title} />;
};

export default CardImg;
