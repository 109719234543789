import styled from 'styled-components';

const textColor = (props) => props?.theme?.SubTitle?.color ?? 'red';
const font = (props) => props?.theme?.SubTitle?.fontFamily ?? 'inherit';
const fontSize = (props) => props?.theme?.SubTitle?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.SubTitle?.fontWeight ?? 'inherit';

const SubTitleStyles = styled.span`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
`;

export default SubTitleStyles;
