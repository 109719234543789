import styled from 'styled-components';
import React from 'react';
import Img from 'gatsby-image';

const StyledImg = styled(Img)`
    height: ${(props) => props.imgHeight};
    max-height: ${(props) => props.maxHeight};
`;

const CardImgStyles = ({ imgHeight = ['370px', null], alt, fluid, title }) => {
    return <StyledImg alt={alt} fluid={fluid} title={title} imgHeight={imgHeight[0]} maxHeight={imgHeight[1]} />;
};

export default CardImgStyles;
