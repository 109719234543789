import React from 'react';
import { Grid } from '@material-ui/core';

import BasicCardStyles from './BasicCardStyles';
import CardTextContainer from '../Common/CardTextContainer';
import CardImg from '../Common/CardImg';
import BlogTitle from '../Common/BlogTitle';
import BlogText from '../Common/BlogText';
import SubTitle from '../Common/SubTitle';
import PostDate from '../Common/PostDate';
// import CustomTitle from '../Common/CustomTitle';

const BasicCard = (props) => {
    const title = props?.title ?? '';
    const text = props?.text ?? '';
    const date = props?.date ?? '';
    const subTitle = props?.subTitle ?? '';
    const fluid = props?.fluid ?? false;

    return (
        <BasicCardStyles>
            <Grid container>
                {/* important top set parent width otherwise gatsby-img will not work properly */}
                {fluid ? (
                    <Grid item style={{ width: '100%' }}>
                        <CardImg alt="Illustrative image related to post" fluid={fluid} title={title} />
                    </Grid>
                ) : (
                    ''
                )}
                <Grid item style={{ width: '100%' }}>
                    <CardTextContainer>
                        <BlogTitle gutterBottom={true}>{title}</BlogTitle>
                        <SubTitle gutterBottom={true}>{subTitle}</SubTitle>
                        <PostDate gutterBottom={true}>{date}</PostDate>
                        <BlogText>{text}</BlogText>
                    </CardTextContainer>
                </Grid>
            </Grid>
        </BasicCardStyles>
    );
};

export default BasicCard;
