import styled from 'styled-components';
import { Card } from '@material-ui/core';

const bgCardColor = (props) => props?.theme?.BasicCard?.backgroundColor ?? 'white';
const textColor = (props) => props?.theme?.BasicCard?.color ?? 'inherit';

const TextCardStyles = styled(Card)`
    color: ${textColor};
    background-color: ${bgCardColor};
    display: flex;
`;

export default TextCardStyles;
