import React from 'react';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import BasicCard from '../components/Cards/BasicCard/BasicCard';

const ErrorPage = () => {

    const pageTitle = '404'

    return (
        <Theme>
            <PageMeta title={pageTitle} />
            <PageContainer>
                <NavBar />
                <MainContainer>
                    <BasicCard title="ERROR" text="Upppsss... something went wrong!"/>
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default ErrorPage;
