import BlogTextStyles from './BlogTextStyles';
import React from 'react';
import { Typography } from '@material-ui/core';

const BlogText = ({ children }) => (
    <Typography gutterBottom variant="body1">
        <BlogTextStyles>{ children }</BlogTextStyles>
    </Typography>
);

export default BlogText;
