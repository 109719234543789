import styled from 'styled-components';

const textColor = (props) => (props?.theme?.BlogTitle?.color ?? 'inherit');
const font = (props) => props?.theme?.BlogTitle?.fontFamily ?? 'inherit';
const fontSize = (props) => props?.theme?.BlogTitle?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.BlogTitle?.fontWeight ?? 'inherit';

const BlogTitleStyles = styled.span`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
`;

export default BlogTitleStyles;
