import SubTitleStyles from './SubTitleStyles';
import React from 'react';
import { Typography } from '@material-ui/core';

const SubTitle = ({ children, ...props }) => (
    <Typography {...props} variant="h6" component="span">
        <SubTitleStyles>{children}</SubTitleStyles>
    </Typography>
);

export default SubTitle;
